/* eslint-disable no-await-in-loop */
import urls from 'urls';
import {EviteModal} from '~/modal/evite_modal';
import {userLoggedIn} from '~/common/components/LoginModal/reducer';
/**
 * Default login time-to-live in milliseconds (7 days)
 */
const DEFAULT_LOGIN_TTL_MS = 604800000;
export const USER_DATA_STORAGE_KEY = 'userData';
let captchaResponse;

/**
 * Save user data to local storage.
 *
 * @param {Object} user - The user object containing user data.
 * @param {string} user.user_id - The user's ID.
 * @param {string} user.full_name - The user's full name.
 * @param {string} user.subscription_plan_name - The user's subscription plan name.
 * @param {string} user.email - The user's email.
 * @param {string} user.avatar_disk - The user's avatar disk.
 * @param {string} user.image_url - The user's image URL.
 * @param {string} user.initials - The user's initials.
 * @param {string} user.first_name - The user's first name.
 */
export const saveUserDataToLocalStorage = (user) => {
  const userData = {
    userId: user.user_id || user.userId,
    userName: user.full_name || user.userName,
    email: user.email,
    initials: user.initials,
    firstName: user.first_name || user.firstName,
    // assume we save data only when user is authenticated, unless isAuthenticated is explicitly set to false
    isAuthenticated: user.isAuthenticated !== false,
    subscriptionPlanName: user.subscription_plan_name || user.subscriptionPlanName,
    avatarDisk: user.avatar_disk || user.avatarDisk,
    imgUrl: user.image_url || user.imgUrl,
    token: user.token,
  };
  const userDataObj = {
    content: userData,
    timestamp: Date.now(),
  };
  window.isAuthenticated = userData.isAuthenticated;
  localStorage.setItem(USER_DATA_STORAGE_KEY, JSON.stringify(userDataObj));
};

/**
 * Load user data from local storage if it hasn't expired.
 *
 * @param {number} [ttl=DEFAULT_LOGIN_TTL_MS] - Time-to-live in milliseconds.
 * @returns {Object|null} - Returns the user data object if it exists and hasn't expired, otherwise null.
 */

const loadUserDataFromLocalStorage = (ttl = DEFAULT_LOGIN_TTL_MS) => {
  const userDataObj = JSON.parse(localStorage.getItem(USER_DATA_STORAGE_KEY));
  if (window.isAuthenticated !== false && userDataObj && Date.now() - userDataObj.timestamp < ttl) {
    return userDataObj.content;
  }
  if (userDataObj) {
    // Delete the stored userData if we are not authenticated due to deleting cookies or the data has expired
    localStorage.removeItem(USER_DATA_STORAGE_KEY);
    return null;
  }
  return null;
};

/**
 * Retrieves user data from the global window object.
 * The function tries to fetch several user-related properties from the window object,
 * and it returns a user data object with default values for any missing properties.
 *
 * @returns {Object} userData - Contains user data fetched from the window object.
 */
const getUserDataFromWindow = () => {
  const userData = {
    userId: window.user_id || '',
    userName: window.userName || '',
    email: typeof window.email === 'string' ? window.email : '',
    initials: window.user_initials || '',
    firstName: window.firstName || '',
    isAuthenticated: window.isAuthenticated || false,
    subscriptionPlanName: window.subscriptionPlanName || '',
    avatarDisk: window.user_avatar_disk || '',
    imgUrl: window.user_img_url || '',
  };
  return userData;
};

/**
 * checkIfAdminIsLoggedIn function.
 * This function checks if the current user is an admin and also if they are not the same as the logged in user.
 *
 * The function works with global `window` object properties `isAdmin` and `user_id`.
 *
 * @param {Object} userData - The user data object. This object should contain the user's unique identification (`userId`).
 * @property {string} userData.userId - The user's unique identification.
 *
 * @returns {boolean} If `isAdmin` property on the `window` object is true and `userId` from `userData` is not equal
 * to `user_id` property on `window` object, function will return `true`, otherwise `false`.
 */
const checkIfAdminIsLoggedIn = (userData) =>
  window.isAdmin ? userData.userId !== window.user_id : false;

/**
 * checkIfUserDataIsValid function.
 * This function checks whether user data should is valid or not. It checks multiple conditions
 * including if the user data exists, is non-empty, if the user is authenticated, has a userId,
 * and if the admin is not logged in.
 *
 * @param {Object} userData - The user data object.
 * @property {boolean} isAuthenticated - Indicates whether the user is authenticated.
 * @property {string} userId - The ID of the user.
 *
 * @returns {boolean} - Returns true if the user data is valid to be saved, false otherwise.
 */
const checkIfUserDataIsValid = (userData) => {
  if (!userData || Object.keys(userData).length === 0) {
    // userData is undefined or empty, so no need to check further
    return false;
  }

  // Now we can be sure userData exists, and we can check the other conditions
  const isUserAuthenticated = userData.isAuthenticated && userData.userId;
  // Check if an admin is not logged in. This accounts for cstool view as host case
  // (localStorage holds the admin user data): we should get the user data from window
  // variables (host data) and replace userData in localStorage with it
  const isAdminNotLoggedIn = !checkIfAdminIsLoggedIn(userData);

  return isUserAuthenticated && isAdminNotLoggedIn;
};

/**
 * Retrieves user data from either local storage or the global window object, depending on data validity.
 * If the user data is valid and retrieved from the window object, it will be saved to local storage.
 * @returns {Object} An object containing the user data properties.
 */
export const getUserData = (webview = false) => {
  let userData;
  if (!webview) userData = loadUserDataFromLocalStorage();
  if (!checkIfUserDataIsValid(userData)) {
    // Data from localStorage is not good or is not there, let's get it from window
    userData = getUserDataFromWindow();
    if (checkIfUserDataIsValid(userData)) {
      // Data from window looks good, let's persist it in localStorage
      saveUserDataToLocalStorage(userData);
    } else {
      // Still not good, should be anonymous viewing
    }
  }
  return userData;
};

/** Captcha helpers */
export class CaptchaWrapper {
  constructor(settings) {
    this.settings = {
      width: '520px',
      ...settings,
    };
    if (this.settings.challenge.type === 'sms') {
      this.settings.url = urls.get('sms_captcha');
    } else if (this.settings.challenge.type === 'ad') {
      this.settings.url = urls.get('ad_captcha');
    } else {
      this.settings.url = urls.get('recaptcha');
    }
    if (this.settings.challenge.captcha_event) {
      this.settings.url = `${this.settings.url + this.settings.challenge.captcha_event}/`;
      if (this.settings.challenge.retry) {
        this.settings.url = `${this.settings.url}retry/`;
      }
    }
    this.settings.url_parameters = {
      challenge: this.settings.challenge,
    };
    // eslint-disable-next-line no-constructor-return
    return new EviteModal(this.settings);
  }
}

export const submitLogin = async ({email, password}) =>
  new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    $.ajax({
      type: 'POST',
      url: '/ajax_login',
      data: {email, password, captcha: captchaResponse},
      dataType: 'json',
      success: (data) => {
        resolve({data});
      },
      error: (xhr, status, error) => {
        if (xhr.status === 412) {
          const data = xhr.responseJSON;
          document.getElementById('evite-modal-container').style.display = 'flex';

          resolve(
            new Promise((res, rej) => {
              new CaptchaWrapper({
                challenge: data,
                onSubmit: (_response) => {
                  captchaResponse = {..._response};
                  captchaResponse.name = undefined;
                  document.getElementById('evite-modal-container').style.display = 'none';
                  res(captchaResponse);
                },
                onCancel: () => {
                  document.getElementById('evite-modal-container').style.display = 'none';
                  rej();
                },
              }).show();
            })
          );
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({xhr, status, error});
        }
      },
    });
  });

export const submitLoginWithCaptcha = async ({email, password}) => {
  let resp = await submitLogin({email, password});

  let numCaptchas = 0;
  while (resp?.type === 'recaptcha' || resp?.type === 'sms') {
    // Enforce captcha
    resp = await submitLogin({email, password});
    if (++numCaptchas > 2) {
      break;
    }
  }

  return resp;
};

export const submitRegistration = (body) =>
  // eslint-disable-next-line no-async-promise-executor
  new Promise(async (resolve, reject) => {
    try {
      const url = urls.get('ajax_register');
      // testcookie is set so we can detect if the user is blocking cookies.
      evite.cookie('testcookie', 'worked', {expires: 14, path: '/'});
      const response = await evite.fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({...body, captcha: captchaResponse}),
      });
      if (response.status === 412) {
        const data = await response.json();
        document.getElementById('evite-modal-container').style.display = 'flex';

        resolve(
          new Promise((res, rej) => {
            new CaptchaWrapper({
              challenge: data,
              onSubmit: (_response) => {
                captchaResponse = {..._response};
                captchaResponse.name = undefined;
                document.getElementById('evite-modal-container').style.display = 'none';
                res(captchaResponse);
              },
              onCancel: () => {
                document.getElementById('evite-modal-container').style.display = 'none';
                rej();
              },
            }).show();
          })
        );
      } else {
        resolve(response);
      }
    } catch (err) {
      reject(err);
    }
  });

export const submitRegistrationWithCaptcha = async (body) => {
  let resp = await submitRegistration(body);

  let numCaptchas = 0;
  while (resp?.type === 'recaptcha' || resp?.type === 'sms') {
    // Enforce captcha
    resp = await submitRegistration(body);
    if (++numCaptchas > 2) {
      break;
    }
  }

  return resp;
};

export const updateUser = async (store) => {
  const url = urls.get('ajax_get_user');
  const response = await evite.fetch(url, {
    method: 'get',
    credentials: 'include',
    cache: 'no-cache',
    headers: {
      'X-CSRFToken': evite.cookie('csrftoken'),
      Accept: 'application/json',
    },
  });
  const data = await response.json();
  if (!response.ok) throw response;
  saveUserDataToLocalStorage(data);
  store.dispatch(
    userLoggedIn(
      data.user_id,
      data.full_name,
      data.subscription_plan_name,
      data.email,
      data.avatar_disk,
      data.image_url,
      data.initials,
      data.first_name
    )
  );
  return data;
};
